import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Select from "react-select";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import LocalizableTextInput from "../../Components/LocalizableInputs/LocalizableTextInput.jsx";
import LocalizableTextArea from "../../Components/LocalizableInputs/LocalizableTextArea.jsx";
import { parseError } from "api/common.js";
import Loader from "views/Components/Loader/Loader";
import MassNotificationsHistoryView from "./HistoryView";

const Notifications = (props) => {
	const [loading, setLoading] = useState(false);
	const [pageSize] = useState(12);
	const [error, setError] = useState(null);
	const [zoneOptions, setZoneOptions] = useState([]);
	const [selectedZones, setSelectedZones] = useState([]);
	const [title, setTitle] = useState("");
	const [text, setText] = useState("");
	const [localizations, setLocalizations] = useState({});
	const [corporateOptions, setCorporateOptions] = useState([]);
	const [selectedCorporates, setSelectedCorporates] = useState([]);
	const [corporateTitle, setCorporateTitle] = useState("");
	const [corporateText, setCorporateText] = useState("");
	const [corporateError, setCorporateError] = useState(null);
	const [corporateLoading, setCorporateLoading] = useState(false);

	useEffect(() => {
		loadZones();
		loadCorporate();
	}, []);

	const loadZones = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/zones`)
			.then((res) => {
				const zones = res.data.map((zone) => ({
					value: zone.id,
					label: zone.name,
				}));
				setZoneOptions(zones);
			})
			.catch((err) => {
				parseError(err);
			});
	};

	const loadCorporate = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/corporate/all`)
			.then((res) => {
				console.log(res);
				const corporates = res.data.map((corp) => ({
					value: corp.id,
					label: corp.name,
				}));
				setCorporateOptions(corporates);
			})
			.catch((err) => {
				parseError(err);
			});
	};

	const sendNotifications = () => {
		setLoading(true);
		setError(null);

		let data = {
			title: title,
			text: text,
			localizations: localizations,
			zones: selectedZones.map((z) => z.value),
		};

		axios
			.post(`${process.env.REACT_APP_API_URL}/notifications/mass/send`, data)
			.then((res) => {
				setLoading(false);

				if (res && res.data) {
					Swal.fire(
						"Push Notification",
						`The notification has been sent for ${res.data.customers} customer(s).`,
						"success"
					);

					if (res.data.customers > 0) {
						window.dispatchEvent(new CustomEvent("newMassNotification"));
					}
				}
			})
			.catch((err) => {
				let error = parseError(err);

				setLoading(false);
				setError(error);
			});
	};

	const sendNotificationsForCorp = () => {
		setCorporateLoading(true);
		setCorporateError(null);

		let data = {
			title: corporateTitle,
			text: corporateText,
			localizations: localizations,
			corporateIds: selectedCorporates.map((corp) => corp.value),
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/notifications/mass/corporatesend`,
				data
			)
			.then((res) => {
				setCorporateLoading(false);

				if (res && res.data) {
					Swal.fire(
						"Push Notification",
						`The notification has been sent for ${res.data.corporatePatients} corporation(s).`,
						"success"
					);

					if (res.data.technicians > 0) {
						window.dispatchEvent(new CustomEvent("newMassNotification"));
					}
				}
			})
			.catch((err) => {
				let error = parseError(err);

				setCorporateLoading(false);
				setCorporateError(error);
			});
	};

	return (
		<div
			className="main-content flex flex-col gap-24"
			style={{ padding: "24px" }}
		>
			<div className="flex gap-10" style={{ width: "100%" }}>
				<Card
					style={{ maxWidth: "816px", width: "50%" }}
					title={
						<div className="flex items-center w-full">
							<div className="title">Customer Push Notifications</div>
						</div>
					}
					content={
						<div className="flex flex-col gap-16">
							<div className="flex flex-col gap-6 w-full">
								<FormGroup>
									<ControlLabel>
										Locations: <span className="star">*</span>
									</ControlLabel>
									<Select
										name="zone-select"
										isClearable={false}
										isDisabled={loading}
										className="react-select react-select-icon w-full"
										isSearchable={false}
										placeholder="Select Service Locations"
										noOptionsMessage={() => "No Service Locations"}
										isMulti={true}
										value={selectedZones}
										onChange={(opt) => {
											setSelectedZones(opt);
										}}
										options={zoneOptions}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>
										Notification Title: <span className="star">*</span>
									</ControlLabel>
									<LocalizableTextInput
										disabled={loading}
										placeholder="Enter notification title (up to 65 characters)..."
										value={title}
										maxLength={65}
										onChange={(text) => {
											setTitle(text);
										}}
										onLocalizationChange={(translation) => {
											setLocalizations((prev) => {
												let newObj = Object.assign({}, prev);
												Object.assign(newObj, translation);
												return newObj;
											});
										}}
										fieldName="Title"
										localizations={localizations}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>
										Notification Text: <span className="star">*</span>
									</ControlLabel>
									<LocalizableTextArea
										disabled={loading}
										placeholder="Enter notification text (up to 150 characters)..."
										value={text}
										maxLength={150}
										rows={5}
										onChange={(text) => {
											setText(text);
										}}
										onLocalizationChange={(translation) => {
											setLocalizations((prev) => {
												let newObj = Object.assign({}, prev);
												Object.assign(newObj, translation);
												return newObj;
											});
										}}
										fieldName="Text"
										localizations={localizations}
									/>
								</FormGroup>

								<div className="flex items-center" style={{ marginLeft: "auto" }}>
									<Button
										disabled={loading || selectedZones.length === 0 || !title || !text}
										onClick={() => sendNotifications()}
										bsStyle="danger"
										fill
									>
										<Svg name="send" className="w-5 h-5" />
										Send
									</Button>
								</div>

								{loading && (
									<div className="flex flex-col items-center justify-center">
										<Loader title="Sending notifications..." />
									</div>
								)}

								{error && (
									<div
										className="error-alert"
										style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
									>
										{error}
									</div>
								)}
							</div>
						</div>
					}
				/>

				<Card
					style={{ maxWidth: "816px", width: "50%" }}
					title={
						<div className="flex items-center w-full">
							<div className="title">Corporate Push Notifications</div>
						</div>
					}
					content={
						<div className="flex flex-col gap-16">
							<div className="flex flex-col gap-6 w-full">
								<FormGroup>
									<ControlLabel>
										Companies: <span className="star">*</span>
									</ControlLabel>
									<Select
										name="zone-select"
										isClearable={false}
										isDisabled={corporateLoading}
										className="react-select react-select-icon w-full"
										isSearchable={false}
										placeholder="Select Service Companies"
										noOptionsMessage={() => "No Service Locations"}
										isMulti={true}
										value={selectedCorporates}
										onChange={(opt) => {
											// setSelectedZones(opt);
											setSelectedCorporates(opt);
										}}
										options={corporateOptions}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>
										Notification Title: <span className="star">*</span>
									</ControlLabel>
									<LocalizableTextInput
										disabled={corporateLoading}
										placeholder="Enter notification title (up to 65 characters)..."
										value={corporateTitle}
										maxLength={65}
										onChange={(text) => {
											setCorporateTitle(text);
										}}
										onLocalizationChange={(translation) => {
											setLocalizations((prev) => {
												let newObj = Object.assign({}, prev);
												Object.assign(newObj, translation);
												return newObj;
											});
										}}
										fieldName="Title"
										localizations={localizations}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>
										Notification Text: <span className="star">*</span>
									</ControlLabel>
									<LocalizableTextArea
										disabled={corporateLoading}
										placeholder="Enter notification text (up to 150 characters)..."
										value={corporateText}
										maxLength={150}
										rows={5}
										onChange={(text) => {
											setCorporateText(text);
										}}
										onLocalizationChange={(translation) => {
											setLocalizations((prev) => {
												let newObj = Object.assign({}, prev);
												Object.assign(newObj, translation);
												return newObj;
											});
										}}
										fieldName="Text"
										localizations={localizations}
									/>
								</FormGroup>

								<div className="flex items-center" style={{ marginLeft: "auto" }}>
									<Button
										disabled={
											corporateLoading ||
											selectedCorporates.length === 0 ||
											!corporateTitle ||
											!corporateText
										}
										onClick={() => sendNotificationsForCorp()}
										bsStyle="danger"
										fill
									>
										<Svg name="send" className="w-5 h-5" />
										Send
									</Button>
								</div>

								{corporateLoading && (
									<div className="flex flex-col items-center justify-center">
										<Loader title="Sending notifications..." />
									</div>
								)}

								{corporateError && (
									<div
										className="error-alert"
										style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
									>
										{corporateError}
									</div>
								)}
							</div>
						</div>
					}
				/>
			</div>

			<MassNotificationsHistoryView />
		</div>
	);
};

export default Notifications;
