import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "views/Components/Loader/Loader";
import { FormControl } from "react-bootstrap";
import Svg from "components/Svg/Svg.jsx";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import { parseError } from "api/common.js";
import Swal from "sweetalert2";
import NoteModal from "./NoteModal.jsx";

function SortableNote(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="service-drag-container">
      <div className="notes-bubble">
        {props.note.description}

        <div className="flex items-center gap-10">
          <button
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(props.note.id);
              }
            }}
            title="Edit note"
            className="btn btn-empty">
            <Svg name="edit" />
          </button>
          <button
            onClick={() => {
              if (props.onDelete) {
                props.onDelete(props.note.id);
              }
            }}
            title="Delete note"
            className="btn btn-empty">
            <Svg name="close" />
          </button>
        </div>
      </div>
    </div>
  );
}

const ServiceNotesPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [questionModalVisible, setQuestionModalVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = () => {
    if (notes.length > 0) {
      return;
    }

    setNotesLoading(true);
    setNotesError(null);
    setNotes([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/service-notes`)
      .then((res) => {
        setNotes(res.data);
        setNotesLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setNotesLoading(false);
        setNotesError(error);
      });
  };

  const deleteNote = (itemId) => {
    Swal.fire({
      title: "Delete Service Note",
      text: `Are you sure you want to delete this service note? This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Note",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/service-notes/${itemId}`)
          .then(() => {
            setNotes((prev) => prev.filter((s) => s.id !== itemId));
            Swal.fire({
              title: `The service note has been deleted successfully.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const editNote = (itemId) => {
    let note = notes.find((n) => n.id == itemId);
    if (note) {
      setSelectedNote(note);
      setNoteModalVisible(true);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setNotes((items) => {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);

        let orderedList = arrayMove(items, oldIndex, newIndex);
        updateNotesOrder(orderedList);
        return orderedList;
      });
    }
  };

  const updateNotesOrder = (updatedList) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/service-notes/order`, {
        services: Object.fromEntries(
          updatedList.map((s) => [`SN#${s.id}`, updatedList.indexOf(s) + 1])
        ),
      })
      .then(() => {})
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Order Update Error", error, "error");
      });
  };

  return (
    <div
      className="main-content flex flex-col gap-16 w-full"
      style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Note Template</div>

            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                setSelectedNote(undefined);
                setNoteModalVisible(true);
              }}>
              <Svg name="plus" className="w-4 h-4" />
              Add Note
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16 w-full">
            <div
              style={{ position: "relative" }}
              className="flex items-center gap-12">
              <Svg
                name="search"
                style={{
                  color: "var(--sub-color)",
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <FormControl
                style={{ paddingLeft: "36px" }}
                disabled={loading}
                type="text"
                maxLength="100"
                name="notes-search-query"
                value={searchQuery}
                placeholder="Search notes..."
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </div>

            {notesLoading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading notes..." />
              </div>
            )}

            {notesError && (
              <div
                className="error-alert"
                style={{
                  margin: "12px auto",
                  padding: "0.6rem",
                  maxWidth: "500px",
                }}>
                {notesError}
              </div>
            )}

            <div
              className="flex gap-16 flex-col items-start"
              style={{
                maxHeight: "calc(100vh - 250px)",
                minHeight: "360px",
                overflowY: "auto",
                paddingRight: "12px",
              }}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement]}>
                <SortableContext
                  items={notes}
                  strategy={verticalListSortingStrategy}>
                  {notes
                    .filter(
                      (q) =>
                        !searchQuery ||
                        q.description
                          .toLowerCase()
                          .indexOf(searchQuery.toLowerCase()) > -1
                    )
                    .map((note) => (
                      <SortableNote
                        key={note.id}
                        id={note.id}
                        note={note}
                        onDelete={deleteNote}
                        onEdit={editNote}
                        index={notes.findIndex((s) => s.id === note.id) + 1}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </div>

            {notes.length === 0 && !notesError && (
              <div
                className="sub-text flex flex-col items-center"
                style={{ margin: "12px auto" }}>
                No notes found...
              </div>
            )}

            <NoteModal
              show={noteModalVisible}
              note={selectedNote}
              onConfirm={(note) => {
                setNotes((prev) =>
                  prev.find((n) => n.id === note.id)
                    ? prev.map((n) => {
                        if (n.id === note.id) {
                          return note;
                        }
                        return n;
                      })
                    : prev.concat([note])
                );
                setNoteModalVisible(false);
                setSelectedNote(undefined);
              }}
              onHide={() => {
                setNoteModalVisible(false);
                setSelectedNote(undefined);
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default ServiceNotesPage;
