import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import "./index.css";
export default function PermissionModal({
	show,
	handleClose,
	selectedAdmin,
	handleSave,
	allPermissions,
	selectedAdminPermission,
}) {
	const [permissions, setPermissions] = useState([]);
	const [disabled, setDisabled] = useState(false);
	useEffect(() => {
		// setPermissions;
		if (allPermissions && selectedAdminPermission) {
			let userPermissions = allPermissions.map((permission) => {
				if (
					selectedAdminPermission.some(
						(adminPermission) => adminPermission.id === permission.id
					)
				) {
					return { ...permission, selected: true };
				} else {
					return { ...permission, selected: false };
				}
			});
			setPermissions(userPermissions);
		}
	}, [allPermissions, selectedAdminPermission]);

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{selectedAdmin.firstName + " " + selectedAdmin.lastName}
					<br />
					{selectedAdmin.email}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="permissionRows">
					{permissions.map((permission) => {
						return (
							<label key={permission.id} className="permissionLableSpan">
								<input
									type="checkbox"
									checked={permission.selected}
									onChange={() => {
										let changePermission = [...permissions];
										let selectedIndex = changePermission.findIndex(
											(eachPer) => eachPer.id === permission.id
										);
										changePermission[selectedIndex].selected =
											!changePermission[selectedIndex].selected;
										setPermissions(changePermission);
									}}
								/>
								<span className="permissionLableSpan">{permission.name}</span>
							</label>
						);
					})}
				</div>
			</Modal.Body>
			<Modal.Footer className="permissionModalFooter">
				<Button
					bsStyle="danger"
					className="ml-auto"
					outline="true"
					disabled={disabled}
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					disabled={disabled}
					bsStyle="danger"
					fill
					onClick={() => {
						handleSave(selectedAdmin.id, permissions);
						setDisabled(!disabled);
					}}
				>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
