import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import {
	parseError,
	renderDatePickerInput,
	dateTimeFormat,
	dateFormat,
} from "api/common.js";
import Select from "react-select";

const MassNotificationsHistoryView = () => {
	const savedPageSize = localStorage.getItem("mass-notifications-page-size")
		? parseInt(localStorage.getItem("mass-notifications-page-size"))
		: 15;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [previousPageToken, setPreviousPageToken] = useState(null);
	const [nextPageToken, setNextPageToken] = useState(null);
	const [actualPageSize, setActualPageSize] = useState(savedPageSize);
	const [selectedPageSize, setSelectedPageSize] = useState({
		value: savedPageSize,
		label: `${savedPageSize.toString()} per page`,
	});
	const [error, setError] = useState(null);
	const [fromDate, setFromDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		window.addEventListener("newMassNotification", reloadNotifications, true);
		return function cleanup() {
			window.removeEventListener("newMassNotification", reloadNotifications, true);
		};
	}, []);

	useEffect(() => {
		localStorage.setItem("mass-notifications-page-size", selectedPageSize.value);
		getNotificationsHistory(nextPageToken);
	}, [selectedPageSize]);

	const reloadNotifications = () => {
		getNotificationsHistory(null, true);
	};

	const getNotificationsHistory = (pageToken = null, isBackward = true) => {
		setLoading(true);
		setError(null);
		setData([]);
		setActualPageSize(selectedPageSize.value);

		axios
			.get(
				`${process.env.REACT_APP_API_URL}/notifications/mass/history?pageSize=${
					selectedPageSize.value
				}&paginationToken=${pageToken || ""}&isBackward=${
					isBackward ? "true" : "false"
				}&from=${
					fromDate
						? `${fromDate.getFullYear()}-${
								fromDate.getMonth() + 1
						  }-${fromDate.getDate()}`
						: ""
				}&to=${
					endDate
						? `${endDate.getFullYear()}-${
								endDate.getMonth() + 1
						  }-${endDate.getDate()}`
						: ""
				}`
			)
			.then((res) => {
				setNextPageToken(res.data.nextPageToken);
				setPreviousPageToken(res.data.previousPageToken);
				setData(mapItems(res.data.items));
				setLoading(false);

				if (res.data.items.length > selectedPageSize.value) {
					setActualPageSize(res.data.items.length);
				}
			})
			.catch((err) => {
				let error = parseError(err);
				setLoading(false);
				setError(error);
			});
	};

	const mapItems = (items) => {
		return items.map((prop) => {
			return mapNotification(prop);
		});
	};

	const mapNotification = (prop) => {
		console.log(prop);
		return {
			id: prop.id,
			title: (
				<div className="flex flex-col items-start gap-10">
					<span>{prop.title}</span>
					{prop.localizations.hasOwnProperty("Title") &&
						Object.keys(prop.localizations["Title"]).map((lKey) => (
							<span className="sub-text" key={lKey}>
								{prop.localizations["Title"][lKey]}
							</span>
						))}
				</div>
			),
			text: (
				<div className="flex flex-col items-start gap-10">
					<span>{prop.text}</span>
					{prop.localizations.hasOwnProperty("Text") &&
						Object.keys(prop.localizations["Text"]).map((lKey) => (
							<span className="sub-text" key={lKey}>
								{prop.localizations["Text"][lKey]}
							</span>
						))}
				</div>
			),
			zones: Object.keys(prop.zones)
				.map((z) => prop.zones[z])
				.join(", "),
			corporateProfiles: prop.corporateProfiles
				? Object.keys(prop.corporateProfiles)
						.map((s) => prop.corporateProfiles[s])
						.join(", ")
				: "",
			sendAt: formatDate(prop.sentAtEpoch),
			sentBy: (
				<div className="flex flex-col">
					<span>{prop.admin.name}</span>
				</div>
			),
		};
	};

	const formatDate = (dateEpoch) => {
		if (!dateEpoch) {
			return "--";
		}
		var d = new Date(0);
		d.setUTCSeconds(dateEpoch);

		return moment(d).format(dateTimeFormat);
	};

	const isValidFromDate = (current) => {
		return endDate ? current.isSameOrBefore(endDate) : true;
	};

	const isValidEndDate = (current) => {
		return fromDate ? current.isSameOrAfter(fromDate) : true;
	};

	return (
		<Card
			title="Notifications History"
			content={
				<div className="flex flex-col gap-16">
					<div style={{ position: "relative" }} className="flex items-center gap-12">
						<Svg
							name="search"
							style={{
								color: "var(--sub-color)",
								position: "absolute",
								left: "12px",
								top: "10px",
								width: "20px",
								height: "20px",
							}}
						/>

						<div style={{ width: "180px", flexShrink: 0 }}>
							<ReactDatetime
								dateFormat={dateFormat}
								value={fromDate}
								closeOnSelect={true}
								renderInput={renderDatePickerInput}
								inputProps={{
									disabled: loading,
									placeholder: "From Date",
								}}
								timeFormat={false}
								isValidDate={isValidFromDate}
								onChange={(e) => setFromDate(e ? moment(e).toDate() : null)}
							/>
						</div>

						<div style={{ width: "180px", flexShrink: 0 }}>
							<ReactDatetime
								dateFormat={dateFormat}
								value={endDate}
								closeOnSelect={true}
								renderInput={renderDatePickerInput}
								inputProps={{
									disabled: loading,
									placeholder: "To Date",
								}}
								timeFormat={false}
								isValidDate={isValidEndDate}
								onChange={(e) => setEndDate(e ? moment(e).toDate() : null)}
							/>
						</div>

						<Button
							disabled={loading}
							loading={loading}
							style={{ height: "38px" }}
							bsStyle="danger"
							fill
							onClick={() => getNotificationsHistory()}
						>
							<Svg name="search" className="w-4 h-4" />
							Search
						</Button>
					</div>

					<div className="separator horizontal"></div>

					<ReactTable
						loading={loading}
						loadingText="Loading notifications history..."
						noDataText={error ? error : loading ? "" : "No notifications found..."}
						data={data}
						columns={[
							{
								Header: "Title",
								accessor: "title",
								sortable: false,
								filterable: false,
								style: { whiteSpace: "unset" },
							},
							{
								Header: "Description",
								accessor: "text",
								sortable: false,
								filterable: false,
								style: { whiteSpace: "unset" },
							},
							{
								Header: "Zones",
								accessor: "zones",
								sortable: false,
								filterable: false,
							},
							{
								Header: "Corporate Profiles",
								accessor: "corporateProfiles",
								sortable: false,
								filterable: false,
							},
							{
								Header: "Sent On",
								accessor: "sendAt",
								sortable: false,
								maxWidth: 170,
								filterable: false,
							},
							{
								Header: "Sent By",
								accessor: "sentBy",
								sortable: false,
								filterable: false,
							},
						]}
						showPaginationBottom={false}
						defaultPageSize={selectedPageSize.value}
						pageSize={actualPageSize}
						className="-striped -highlight"
					/>
					<div className="flex items-center" style={{ marginLeft: "auto" }}>
						<Select
							name="page-size-select"
							isClearable={false}
							isDisabled={loading}
							className="react-select mr-10 w-148"
							isSearchable={false}
							placeholder="Items Per Page"
							noOptionsMessage={() => "No Options"}
							isMulti={false}
							menuPlacement="top"
							value={selectedPageSize}
							onChange={(opt) => {
								setSelectedPageSize({
									value: opt.value,
									label: `${opt.value} per page`,
								});
							}}
							options={[
								{ value: 10, label: "10" },
								{ value: 15, label: "15" },
								{ value: 20, label: "20" },
								{ value: 25, label: "25" },
								{ value: 40, label: "40" },
								{ value: 50, label: "50" },
							]}
						/>
						<Button
							className="pagination-btn left btn-fill"
							disabled={!previousPageToken || loading}
							onClick={() => getNotificationsHistory(previousPageToken, false)}
							bsStyle="default"
							fill
						>
							<Svg name="chevron-left" className="w-6 h-6" />
						</Button>
						<Button
							className="pagination-btn right btn-fill"
							disabled={!nextPageToken || loading}
							onClick={() => getNotificationsHistory(nextPageToken, true)}
							bsStyle="default"
							fill
						>
							<Svg name="chevron-right" className="w-6 h-6" />
						</Button>
					</div>
				</div>
			}
		/>
	);
};

export default MassNotificationsHistoryView;
